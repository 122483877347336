
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const Subscribes = createApi({
  reducerPath: 'subscribes',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  tagTypes: ['subscribes'],
  endpoints: (builder) => ({
    createSubscribes: builder.mutation({
      query: (data) => {
       // console.log("datas",data);
        return{
            url: '/api/subscribes/',
            method: 'POST',
            body: {
              data: {
                Email_id: data.Email_id
              }
            },
            headers: {
              'Content-type': 'application/json'
            },
        }
      
      },
      invalidatesTags: ['subscribes'],
    }),

  }),
})

export const {  useCreateSubscribesMutation} = Subscribes;