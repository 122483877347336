import React from 'react'
import Header from './Header'
import Footer from "./Footer";
import { Outlet } from 'react-router-dom'
import ScrollToTop from '../Component/Common/ScrollToTop';
export default function Layout() {
  return (
    <>
      <Header/>
      <ScrollToTop>  <Outlet /></ScrollToTop>
    
      <Footer />
    </>
  )
}