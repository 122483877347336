import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
  
export const communicationsDataApiCall = createApi({
  reducerPath: 'communicationsDataApiCall',
  tagTypes: ['communicationsData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchCommunicationsData: build.query({
      query: () => {
        return ({
          url: `/api/communications?sort[0]=id:desc&populate=*`,
          
        })
      },
     
      providesTags: ['communicationsData']
    }),


 
  })
})
 
export const {
  useFetchCommunicationsDataQuery,
 
} =communicationsDataApiCall;
 