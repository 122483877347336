import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
 
 
export const menuApiCall = createApi({
  reducerPath: 'menuApiCall',
  tagTypes: ['layouts'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchMenu: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/navigation/render/2`,
          params: params,
        })
      },
      transformResponse:(data)=> {
        const dataMap = {}; 
        if(data.length){
            data?.forEach(item =>dataMap[item.id] = {  ...item, children: []});
            const hierarchy = [];
            data?.forEach(item => {
              if
                (item.parent === null) {
                hierarchy.push(dataMap[item.id]);
              }
              else {
                dataMap[item.parent.id].children.push(dataMap[item.id]);
              }
        
            });
        
            return hierarchy;
        }
      },
      providesTags: ['layouts']
    }),
// for log

fetchLogo: build.query({
  query: (params = {}) => {
    return ({
      url: `/api/logos?populate=*`,
      params: params,
    })
  },
 
  providesTags: ['layouts']
}),
    
    addGridLayout: (build).mutation({
      invalidatesTags: ["layouts"],

      query: (data) => ({
        url: `/TreeLayout/`,
        body: data,
        method: 'POST'
      }),
//transformResponse
      transformErrorResponse: (error) => {
        console.log('Add Grid Layout Error Log ====>', error)
        // Swal.fire(error.data, `Status Code: ${error.status}`, 'error');
        return error;
      }
    }),
 
  })
})
 
export const {
  useFetchMenuQuery,useAddGridLayoutMutation,useFetchLogoQuery
} = menuApiCall;
 