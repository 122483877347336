import React, { useEffect, useState, useCallback, useRef } from "react";
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import photo_icone_hand from "../assets/images/photo-icon-h.png";
import video_icone_hand from "../assets/images/video-icon-h.png"
import { useFetchGalleryDataQuery, useFetchPhotoGalleryDataQuery, useFetchVideoGalleryDataQuery } from "../store/rtk/Gallery";
import { REACT_APP_BASE_BACKEND_URL } from "../config";
import Banner from "./Banner";
import { Loader } from "../shared/Loader";
import "yet-another-react-lightbox/styles.css";



const Gallery = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(6);
  const [multiImages, setMultiImages] = useState([]);
  const [multiVideo, setMultiVideo] = useState([]);
  const [currentPage_photo, setCurrentPage_photo] = useState(1);
  const [pageSize_photo, setpageSize_photo] = useState(6);
  const [currentPage_video, setCurrentPage_video] = useState(1);
  const [pageSize_video, setpageSize_video] = useState(6);
  const [allData, setAllData] = useState([]);
  const [allPhoto, setAllPhoto] = useState([]);
  const [allVideo, setAllVideo] = useState([]);
  const { isFetching, isLoading, data } = useFetchGalleryDataQuery({ currentPage, pageSize });
  const { isFetchingphoto, isLoadingphoto, data: photo } = useFetchPhotoGalleryDataQuery({ currentPage_photo, pageSize_photo });
  const { isFetchingpvideo, isLoadingpvideo, data: video } = useFetchVideoGalleryDataQuery({ currentPage_video, pageSize_video });
  const [open, setOpen] = useState(false);

  const [controls, setControls] = useState(true);
  const [playsInline, setPlaysInline] = useState(true);
  const [autoPlay, setAutoPlay] = useState(false);
  const [loop, setLoop] = useState(false);
  const [muted, setMuted] = useState(false);
  const [disablePictureInPicture, setDisablePictureInPicture] = useState(false);
  const [disableRemotePlayback, setDisableRemotePlayback] = useState(false);
  //const [controlsList, setControlsList] =useState<("nodownload" | "nofullscreen" | "noremoteplayback")[0]>([]);
  const [crossOrigin, setCrossOrigin] = useState("");
  const [preload, setPreload] = useState("");



  const lightGallery = useRef(null);
  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);


  useEffect(() => {
    if (data) {
      setAllData(prevData => [...prevData, ...data.data]);
    }
  }, [data]);
  useEffect(() => {
    if (photo) {
      setAllPhoto(prevPhotoData => [...prevPhotoData, ...photo.data]);
    }
  }, [photo]);
  useEffect(() => {
    if (video) {
      setAllVideo(prevVideoData => [...prevVideoData, ...video.data]);
    }
  }, [video]);
  const handleShowMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  }
  const handlePhotoShowMore = () => {
    setCurrentPage_photo(prevPage1 => prevPage1 + 1)
  }
  const handleVideoShowMore = () => {
    setCurrentPage_video(prevPage2 => prevPage2 + 1)
  }

  const hasMore = currentPage < Math.ceil(data?.meta?.pagination?.total / pageSize);
  const hasMore1 = currentPage_photo < Math.ceil(photo?.meta?.pagination?.total / pageSize);
  const hasMore2 = currentPage_video < Math.ceil(video?.meta?.pagination?.total / pageSize);


  const OnmultiClick = (items) => {
    const imageUrls = items?.attributes?.file_name?.data.map(item => ({
      src: REACT_APP_BASE_BACKEND_URL + item?.attributes?.url,
      thumb: REACT_APP_BASE_BACKEND_URL + item?.attributes?.url,
      type: item.attributes?.media_type === "Video" ? 'video/mp4' : 'image'
    })) || [];
    setMultiImages(imageUrls);

  };


  const OnmultiClickVideo = (items) => {
    const imageUrls = items?.attributes?.file_name?.data.map(item => ({
      type: "video",
      width: 1280,
      height: 720,
      poster: "/public/poster-image.jpg",
      sources: [
        {
          src: REACT_APP_BASE_BACKEND_URL + item?.attributes?.url,
          type: "video/mp4",
        },
      ],
    })) || [];
    setMultiVideo(imageUrls);
    setOpen(true)

  };


  return (
    <>    
    

      {isFetching && <Loader />}
      <section className="banner_image_common">
        <Banner urlName={"gallery"} />
      </section>

    
      <section
        className="gallery_section"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <nav>
            <div
              className="nav nav-tabs gallery_tabs mb-3"
              id="nav-tab"
              role="tablist"
            >
              <button
                className="nav-link active"
                id="nav-all-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-all"
                type="button"
                role="tab"
                aria-controls="nav-all"
                aria-selected="true"
              >
                All
              </button>
              <button
                className="nav-link"
                id="nav-photo-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-photo"
                type="button"
                role="tab"
                aria-controls="nav-photo"
                aria-selected="false"
              >
                Photo Gallery
              </button>
              <button
                className="nav-link"
                id="nav-video-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-video"
                type="button"
                role="tab"
                aria-controls="nav-video"
                aria-selected="false"
              >
                Video Gallery
              </button>
            </div>
          </nav>

          <div className="nuc_galiery">
            <div className="tab-content p-3" id="nav-tabContent">
              <div
                className="tab-pane fade active show"
                id="nav-all"
                role="tabpanel"
                aria-labelledby="nav-all-tab"
              >
                <div className="row gy-5 gx-5">

                  <LightGallery
                    closable={true}
                    onInit={onInit}
                   // elementClassNames={'gallery'}
                    dynamic={true}
                    escKey
                    controls
                    plugins={[lgZoom, lgVideo, lgThumbnail]}
                    dynamicEl={multiImages}
                  ></LightGallery>
                  <Lightbox
                    open={open}
                    close={() => setOpen(false)}
                    plugins={[Video]}
                    slides={multiVideo}
                    video={{
                      controls,
                      playsInline,
                      autoPlay,
                      loop,
                      muted,
                      disablePictureInPicture,
                      disableRemotePlayback,
                      //controlsList: controlsList.join(" "),
                      crossOrigin,
                      preload,
                    }}

                  />

                  {allData.map((item, i) => (
                    <div key={i} className="col-lg-4 col-md-6 card-group image_data">
                      <div className="card border-0 gallery_card">
                        {item.attributes?.media_type === "Photo" && (
                          <img
                            src={`${REACT_APP_BASE_BACKEND_URL}${item.attributes?.file_name?.data?.[0]?.attributes?.url}`}
                            className="img-fluid"
                            alt="img"
                          />
                        )}
                        {item.attributes?.media_type === "Video" && (
                          <video width="100%" controls>
                            <source
                              src={`${REACT_APP_BASE_BACKEND_URL}${item.attributes?.file_name?.data?.[0]?.attributes?.url}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}

                        <div className="card-body">
                          <h5 className="card-title">
                            {item.attributes?.title}
                          </h5>
                          <a href="#popup"> <img src={item.attributes?.media_type === "Photo" ? photo_icone_hand : video_icone_hand}
                            onClick={item.attributes?.media_type === "Video" ? () => OnmultiClickVideo(item) : () => {
                              OnmultiClick(item)
                              setTimeout(() => {
                                lightGallery.current.openGallery();
                              }, 200)
                            }}
                          /></a>


                        </div>
                      </div>
                    </div>
                  ))}
                  {!isLoading && !isFetching && hasMore && (
                    <div className="show_more">
                      <button className="btn btn-dark btn-title" onClick={handleShowMore}>
                        SHOW MORE
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="nav-photo"
                role="tabpanel"
                aria-labelledby="nav-photo-tab"
              >
                <div className="row gy-5 gx-5">
              

                  {allPhoto?.map((item, i) => (
                    <div key={i} className="col-lg-4 col-md-6 card-group image_data">
                      <div className="card border-0 gallery_card">
                        {item.attributes?.media_type === "Photo" && (<>
                          <img src={`${REACT_APP_BASE_BACKEND_URL}${item.attributes?.file_name?.data?.[0]?.attributes?.url}`}
                            className="img-fluid"
                            alt="img"
                            onClick={() => {
                              OnmultiClick(item)
                              setTimeout(() => {
                                lightGallery.current.openGallery();
                              }, 200);
                            }}
                          />



                        </>)}

                        <div className="card-body">
                          <a href="#popup">    <h5 className="card-title" onClick={() => {
                            OnmultiClick(item)
                            setTimeout(() => {
                              lightGallery.current.openGallery();
                            }, 200);
                          }}  >
                            {item.attributes?.title}
                          </h5></a>
                          <a href="#popup"> <img src={photo_icone_hand} onClick={() => {
                            OnmultiClick(item)
                            setTimeout(() => {
                              lightGallery.current.openGallery();
                            }, 200);
                          }}         ></img></a>
                        </div>




                      </div>
                    </div>
                  ))}
                  {!isLoadingphoto && !isFetchingphoto && hasMore1 && (
                    <div className="show_more">
                      <button className="btn btn-dark btn-title" onClick={handlePhotoShowMore}>
                        SHOW MORE
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-video"
                role="tabpanel"
                aria-labelledby="nav-video-tab"
              >
                <div className="row gy-5 gx-5">
                  {allVideo?.map((item, i) => (
                    <div key={i} className="col-lg-4 col-md-6 card-group image_data">
                      <div className="card border-0 gallery_card">
                        {item.attributes?.media_type === "Video" && (
                          <video width="100%" controls>
                            <source
                              src={`${REACT_APP_BASE_BACKEND_URL}${item.attributes?.file_name?.data?.attributes?.url}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}

                        <div className="card-body">
                          <h5 className="card-title">
                            {item.attributes?.title}
                          </h5>
                          <a href="#popup"> <img src={video_icone_hand} onClick={() => {
                            OnmultiClickVideo(item)
                          }} /></a>
                        </div>
                      </div>
                    </div>
                  ))}
                  {!isLoadingpvideo && !isFetchingpvideo && hasMore2 && (
                    <div className="show_more">
                      <button className="btn btn-dark btn-title" onClick={handleVideoShowMore}>
                        SHOW MORE
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Gallery and video section --> */}
    </>
  );
};

export default Gallery;
