import { configureStore } from '@reduxjs/toolkit';
import { menuApiCall } from './rtk/menu';
import { bannerApiCall } from './rtk/banner';
import { homeApiCall } from './rtk/commanHome';
import { aboutUsApiCall } from './rtk/aboutus';
import { portfolioPagesApiCall } from './rtk/portfoiloPages';
import { esgPagesApiCall } from './rtk/EsgPage';
import { galleryDataApiCall } from './rtk/Gallery';
import {portfolioKosmoOneApiCall} from './rtk/portfolioKosmoOne';
import { contactUsApiCall } from './rtk/contactUs';
import {PortfolioFintechOneApiCall} from './rtk/PortfolioFintechOne';
import{PortfolioConceptTechParkApiCall} from './rtk/PortfolioConceptTechPark';
import {PortfolioExoraBusinessParkApiCall} from './rtk/PortfolioExoraBusinessPark';
import {PortfolioOneTradeTowerApiCall} from './rtk/PortfolioOneTradeTower';
import {EventsApiCall} from './rtk/Events';
import { blogDataApicall } from './rtk/blogs';
import { contactLoctionApiCall } from './rtk/contactLoction';
import { leaderShipDetailApiCall } from './rtk/leaderShipDetails';
import { oneBkcPagesApiCall } from './rtk/OneBkc';
import { careerDataApiCall } from './rtk/Career ';
import { Subscribes } from './rtk/Subscribes';
import {NewsApiCall} from './rtk/news'
import { PrivacyPolicyApiCall } from './rtk/PrivacyPolicy';
import {investorDataApiCall} from './rtk/investor';
import {oicplCommitteeDataApiCall} from './rtk/oicplCommittee';
import { oicplIvestorApiCall } from './rtk/oicplInvesto';
import { nopplInvesterRelationApiCall } from './rtk/nopplInvestorRelation';
import {communicationsDataApiCall} from './rtk/Communications'
import { esgReportPagesApiCall } from './rtk/EsgReport';

const store = configureStore({
  reducer: {
    [menuApiCall.reducerPath]: menuApiCall.reducer,
    [bannerApiCall.reducerPath]:bannerApiCall.reducer,
    [homeApiCall.reducerPath]:homeApiCall.reducer,
    [aboutUsApiCall.reducerPath]:aboutUsApiCall.reducer,
    [portfolioPagesApiCall.reducerPath]:portfolioPagesApiCall.reducer,
    [esgPagesApiCall.reducerPath]:esgPagesApiCall.reducer,
    [galleryDataApiCall.reducerPath]:galleryDataApiCall.reducer,
    [portfolioKosmoOneApiCall.reducerPath]:portfolioKosmoOneApiCall.reducer,
    [contactUsApiCall.reducerPath]:contactUsApiCall.reducer,
    [PortfolioFintechOneApiCall.reducerPath]:PortfolioFintechOneApiCall.reducer,
    [PortfolioConceptTechParkApiCall.reducerPath]:PortfolioConceptTechParkApiCall.reducer,
    [PortfolioExoraBusinessParkApiCall.reducerPath]:PortfolioExoraBusinessParkApiCall.reducer,
    [PortfolioOneTradeTowerApiCall.reducerPath]:PortfolioOneTradeTowerApiCall.reducer,
    [oneBkcPagesApiCall.reducerPath]:oneBkcPagesApiCall.reducer,
    [careerDataApiCall.reducerPath]:careerDataApiCall.reducer,
    [Subscribes.reducerPath]:Subscribes.reducer,
    [EventsApiCall.reducerPath]:EventsApiCall.reducer,
    [contactLoctionApiCall.reducerPath]:contactLoctionApiCall.reducer,
    [blogDataApicall.reducerPath]:blogDataApicall.reducer,
    [NewsApiCall.reducerPath]:NewsApiCall.reducer,
    [leaderShipDetailApiCall.reducerPath]:leaderShipDetailApiCall.reducer,
    [PrivacyPolicyApiCall.reducerPath]:PrivacyPolicyApiCall.reducer,
    [investorDataApiCall.reducerPath]:investorDataApiCall.reducer,
    [oicplCommitteeDataApiCall.reducerPath]:oicplCommitteeDataApiCall.reducer,
    [oicplIvestorApiCall.reducerPath]:oicplIvestorApiCall.reducer,
    [nopplInvesterRelationApiCall.reducerPath]:nopplInvesterRelationApiCall.reducer,
    [communicationsDataApiCall.reducerPath]:communicationsDataApiCall.reducer,
    [esgReportPagesApiCall.reducerPath]:esgReportPagesApiCall.reducer
  },
 
 middleware: (getDefaultMiddleware) =>  getDefaultMiddleware().concat(menuApiCall.middleware).concat(bannerApiCall.middleware).concat(homeApiCall.middleware)
 .concat(aboutUsApiCall.middleware).concat(portfolioPagesApiCall.middleware).concat(esgPagesApiCall.middleware).concat(galleryDataApiCall.middleware)
    .concat(portfolioKosmoOneApiCall.middleware).concat(contactUsApiCall.middleware)
    .concat(portfolioKosmoOneApiCall.middleware).concat(PortfolioFintechOneApiCall.middleware).concat(PortfolioConceptTechParkApiCall.middleware)
    .concat(PortfolioExoraBusinessParkApiCall.middleware).concat(PortfolioOneTradeTowerApiCall.middleware)
    .concat(portfolioKosmoOneApiCall.middleware).concat(oneBkcPagesApiCall.middleware)
    .concat(careerDataApiCall.middleware)
    .concat(Subscribes.middleware).concat(Subscribes.middleware).concat(EventsApiCall.middleware)
    .concat(Subscribes.middleware).concat(Subscribes.middleware).concat(contactLoctionApiCall.middleware)
    .concat(Subscribes.middleware).concat(Subscribes.middleware).concat(EventsApiCall.middleware)
    .concat(NewsApiCall.middleware).concat(blogDataApicall.middleware).concat(leaderShipDetailApiCall.middleware)
    .concat(PrivacyPolicyApiCall.middleware).concat(investorDataApiCall.middleware).concat(oicplCommitteeDataApiCall.middleware).concat(oicplIvestorApiCall.middleware).concat(nopplInvesterRelationApiCall.middleware)
    .concat(communicationsDataApiCall.middleware).concat(esgReportPagesApiCall.middleware),

    
    
  });
 

export default store; 