import React, { useState } from 'react'
import { useFetchEsgReportPagesDataQuery } from '../store/rtk/EsgReport';
import { REACT_APP_BASE_BACKEND_URL } from '../config';
import { Loader } from '../shared/Loader';
const EsgReport = () => {
  const { isLoading,data } = useFetchEsgReportPagesDataQuery()
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <>
    {isLoading && <Loader/>}
    <section
      className="gallery_section"
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>

            <nav>
              <div
                className="nav nav-tabs gallery_tabs  justify-content-center"
                id="nav-tab"
                role="tablist"
              >
                {data?.data?.map((item, index) => {
                  console.log("item", item);
                  return (
                    <nav key={index}>
                      <div
                        className={`av nav-tabs gallery_tabs  justify-content-center ${activeTab === index ? "show active" : ""
                          }`}
                        id="nav-tab"
                        role="tablist"
                      >

                        <button
                          className={`nav-link ${activeTab === index ? "active" : ""
                            }`}
                          onClick={() => handleTabClick(index)}
                          role="tab"
                          aria-controls={item?.attributes?.Tab_title?.replace(
                            /\s+/g,
                            "-"
                          ).toLowerCase()}
                        >
                          {item?.attributes?.Tab_title}
                        </button>
                      </div>
                    </nav>
                  );
                })}
              </div>
            </nav>
            <div className="nuc_galiery">

              <div className="tab-content" id="nav-tabContent">

                <div
                  className="tab-content gallery-tab-content"
                  id="myTabContent"
                >
                  {data?.data?.map(
                    (item, index1) => {
                      const url=item?.attributes?.zip_file?.data?.attributes?.name
                     const newFileName = url.replace(/\.zip$/i,"");
                     console.log("url",REACT_APP_BASE_BACKEND_URL + `/uploads/${newFileName}/index.html`);
                      return (
                        <div
                          key={index1}
                          className={`tab-pane fade ${activeTab === index1 ? "show active" : ""
                            }`}
                          id={item?.attributes?.Tab_title?.replace(
                            /\s+/g,
                            "-"
                          ).toLowerCase()}
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <h2 class="fs-32 fw-bold salient-title  underline-title  position-relative certified-title text-center">{item?.attributes?.Title}</h2>
                          <div className="row gallery-card-row gx-5 gy-4">
                            <div className="card border-0 gallery-card">
                              <iframe  src={ REACT_APP_BASE_BACKEND_URL + `/uploads/${newFileName}/index.html` } style={{height:"100vh", width:"100%"}} title={item?.attributes?.Title} ></iframe >
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>

   
  )
}

export default EsgReport