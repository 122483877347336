import React from "react";
import Banner from "./Banner";
import { Helmet } from 'react-helmet';
import { REACT_APP_BASE_BACKEND_URL, REACT_APP_API_URL } from "../config";
import { useFetchAboutUsDataQuery } from "../store/rtk/aboutus";
import CountUp from "react-countup";
import envelope from "../assets/images/envelope-regular.svg";
import Team from "./Team";
import { Loader } from "../shared/Loader";

function AboutUs() {

  const { isLoading, data } = useFetchAboutUsDataQuery();


  return (
    <>
      {isLoading && <Loader />}
      <Helmet>
        <title>About us page</title>
        <meta
          name="description"
          content="this is about page of nuclous park"
        />
      </Helmet>
      <section className="aboutus_banner_image">
        <Banner urlName={"aboutus"} />
      </section>
      {/* <!-- about banner secttion --> 

      {/* <!-- AboutUs section -->*/}
      <section className="about_section" c data-aos-duration="1500">
        <div className="container">
          <div className="row gx-5 column_reverse">
            <div className="col-lg-6 col-md-5">
              <div className="left_image">
                <img
                  src={
                    REACT_APP_BASE_BACKEND_URL +
                    data?.data?.attributes?.aboutus_section?.data?.attributes
                      ?.image?.data?.attributes?.url
                  }
                  alt="aboutus"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-7 d-flex align-items-center">
              <div className="right_about_contnet">
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      data?.data?.attributes?.aboutus_section?.data?.attributes
                        ?.description,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- counter section --> */}
      <section
        className="counter-section"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="counter_wrapper">
            <div className="row gy-4">
              <div className="col-lg-3 col-6">
                <div className="row gy-4 counter_icon">
                  <div className="col-lg-4 col-md-3">
                    <img
                      src={`${REACT_APP_API_URL}/assets/Svg/footprint.svg`}
                      alt=""
                      className="img-fluid "
                    />
                  </div>
                  <div className="col-lg-8 col-md-9">
                    <div className="counter_data">
                      <h3>
                        <span
                          className="counter"
                          data-countto="19.6"
                          data-duration="8000"
                        ></span>
                        <span className="stat-suffix">
                          <CountUp
                            end={
                              data?.data?.attributes?.portfolio_counts?.data[0]
                                ?.attributes?.Footprint
                            }
                            decimals={1} // Set the number of decimal places
                          />
                          mn
                        </span>
                      </h3>

                      <p>Sqft of Footprint</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="row gy-4 counter_icon">
                  <div className="col-lg-4 col-md-3">
                    <img
                      src={`${REACT_APP_API_URL}/assets/Svg/corporate-tenants.svg`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-8 col-md-9">
                    <div className="counter_data">
                      <h3>
                        <span
                          className="counter"
                          data-countto="200"
                          data-duration="8000"
                        ></span>
                        <span className="stat-suffix">
                          <CountUp
                            end={
                              data?.data?.attributes?.portfolio_counts?.data[0]
                                ?.attributes?.tenants
                            }
                          />
                          +
                        </span>
                      </h3>

                      <p>Corporate Tenants</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="row gy-4 counter_icon">
                  <div className="col-lg-4 col-md-3">
                    <img
                      src={`${REACT_APP_API_URL}/assets/Svg/commercial-assests.svg`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-8 col-md-9">
                    <div className="counter_data">
                      <h3>
                        <span
                          className="counter"
                          data-countto="16"
                          data-duration="8000"
                        ></span>
                        <span className="stat-suffix">
                          <CountUp
                            end={
                              data?.data?.attributes?.portfolio_counts?.data[0]
                                ?.attributes?.Assets
                            }
                          />
                        </span>
                      </h3>
                      <p>
                         Grade A Commercial<br></br>Parks
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="row gy-4 counter_icon">
                  <div className="col-lg-4 col-md-3">
                    <img
                      src={`${REACT_APP_API_URL}/assets/Svg/park-users.svg`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-8 col-md-9">
                    <div className="counter_data">
                      <h3>
                        <span
                          className="counter"
                          data-countto="120"
                          data-duration="8000"
                        ></span>
                        <span className="stat-suffix">
                          <CountUp
                            end={
                              data?.data?.attributes?.portfolio_counts?.data[0]
                                ?.attributes?.users
                            }
                          />
                          k+
                        </span>
                      </h3>
                      <p>Park Users</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- counter section -->

         {/* <!-- Leadership section --> */}

      <section
        className="leadership"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="leadership_content">
            <h3>Leadership</h3>
          </div>
          <Team />
        </div>
      </section>
      {/* <!-- Leadership section --> */}

      {/* noc park */}
      <section className="nocpark" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-5 col-12">
              <div className="left_item">
                <h3>Nucleus Office Parks</h3>
              </div>
            </div>
            <div className="col-md-12 col-lg-7 col-12 right_item">
              <nav className="tab_menu_nop_sec">
                <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                  {data?.data?.attributes?.Tab.map((item, index) => {
                    return (
                      <button
                        className={`nav-link ${index === 0 ? "active" : ""}`}
                        id="nav-vision-tab"
                        data-bs-toggle="tab"
                        data-bs-target={`#nav-${item?.Tab}`}
                        type="button"
                        role="tab"
                        aria-controls="nav-vision"
                        aria-selected="true"
                      >
                        {item?.Tab}
                      </button>
                    );
                  })}
                </div>
              </nav>
            </div>
          </div>

          <div className="tab_data">
            <div className="row">
              <div className="col-md-12 position-relative nuc_park p-0">
                <div
                  className="tab-content tab_content  border bg-light"
                  id="nav-tabContent"
                >
                  {data?.data?.attributes?.Tab.map((item, index) => {
                    return (
                      <div
                        className={`tab-pane fade  ${index === 0 ? "active show" : ""
                          } `}
                        id={`nav-${item?.Tab}`}
                        role="tabpanel"
                        aria-labelledby="nav-vision-tab"
                      >
                        <p
                          dangerouslySetInnerHTML={{ __html: item?.Content }}
                        ></p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* noc park */}

      {/* <!-- transform noc --> */}
      <section
        className="transform_section"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="transform_content">
                <h3>{data?.data?.attributes?.Transform?.Title}</h3>
              </div>
            </div>
            <div className="col-md-12 col-lg-8">
              <div className="transform_details">
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.data?.attributes?.Transform?.Description,
                  }}
                ></p>
              </div>
            </div>
          </div>
          <div className="row gx-5">
            {data?.data?.attributes?.Transform?.images?.data?.map(
              (item, index) => {
                return (
                  <div className="col-md-6 col-lg-4 position-relative">
                    <div className="card_image">
                      <div className="card">
                        <img
                          src={
                            REACT_APP_BASE_BACKEND_URL + item?.attributes?.url
                          }
                          className="card-img-top"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </section>
      {/* <!-- transform noc --> */}

      {/* <!-- culture section --> */}
      <section
        className="culture_section"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-6 col-md-5">
              <div className="left_image">
                <img
                  src={
                    REACT_APP_BASE_BACKEND_URL +
                    data?.data?.attributes?.Valuable_culture?.image?.data
                      ?.attributes?.url
                  }
                  alt="about_image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-7 d-flex align-items-center">
              <div className="right_about_contnet">
                <h3>{data?.data?.attributes?.Valuable_culture?.Title}</h3>
                <div dangerouslySetInnerHTML={{
                  __html:
                    data?.data?.attributes?.Valuable_culture?.description,
                }} />
                {/* <div className="envelope pt-5">
                  <img src={envelope} alt="imge" /> <span><a href="mailto:hr@nucleusofficeparks.com">hr@nucleusofficeparks.com</a></span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- culture section --> */}
    </>
  );
}

export default AboutUs;
