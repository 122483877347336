import React from "react";
import { useFetchOicplContactDataQuery } from "../store/rtk/oicplCommittee";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import { Loader } from "../shared/Loader";
const OicplContactus = () => {
    const {isFetching, data } = useFetchOicplContactDataQuery();
    return (
        <>
            {/* banner */}
            {isFetching && <Loader/>}
            <section className="banner_image_common">
                     <Banner urlName={"investor-sub-pages"} />
            </section>
            {/* banner */}
            {/* OicplContactus */}
            <section className="investore">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light nav_section">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" id="nav-one-link" to="/investors" role="tab" aria-controls="nav-one" aria-selected="true">
                                    One International Center Private Limited
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" id="nav-office-link" to="/noppl-contactus" role="tab" aria-controls="nav-office" aria-selected="false">
                                    Nucleus Office Parks Private Limited
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="row investore_heading justify-content-between  "><div className="col-md-2"><h1 className="mt-3 mb-lg-0 mb-4">
                        <Link to="/investors"><i className="bi bi-arrow-left"></i> Back</Link></h1></div><div className="col-md-8 text-center"><h1 className="pt-0 ">{data?.data?.attributes?.Title}</h1></div><div className="col-md-2"></div></div>
                    <div className="nuc_galiery investore_page ">
                        <h2 dangerouslySetInnerHTML={{__html: data?.data?.attributes?.Content}}></h2>
                    </div>
                </div>
            </section>
            {/* OicplContactus */}
        </>
    );
};
export default OicplContactus;
