import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
 
 
export const oicplIvestorApiCall = createApi({
  reducerPath: 'oicplIvestorApiCall',
  tagTypes: ['oicplIvestorData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchOicplIvestorData: build.query({
      query: (params = {}) => {
        return ({
          url: `api/oicpl-investor?populate=Investor_tab.Investor_file.File`,
       
        })
      },
     
      providesTags: ['oicplIvestorData']
    }),

 
  })
})
 
export const {
  useFetchOicplIvestorDataQuery
} = oicplIvestorApiCall;
 