import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
  
export const galleryDataApiCall = createApi({
  reducerPath: 'galleryDataApiCall',
  tagTypes: ['galleryData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchGalleryData: build.query({
      query: ({currentPage,pageSize}) => {
        //console.log(currentPage,pageSize)
        return ({
          url: `/api/galleries?sort[0]=id:desc&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage}&populate=*`,
        })
      },
     
      providesTags: ['galleryData']
    }),

    fetchPhotoGalleryData: build.query({
      query: ({currentPage_photo, pageSize_photo}) => {
       // debugger
       // console.log(currentPage_photo, pageSize_photo)
        return ({
          url: `/api/galleries?filters[media_type]=photo&sort[0]=id:desc&pagination[pageSize]=${pageSize_photo}&pagination[page]=${currentPage_photo}&populate=*`,
          
        })
      },
     
      providesTags: ['photoData']
    }),

    fetchVideoGalleryData: build.query({
      query: ({currentPage_video, pageSize_video}) => {
       // console.log(currentPage_video, pageSize_video)
        return ({
          url: `/api/galleries?filters[media_type]=video&sort[0]=id:desc&pagination[pageSize]=${pageSize_video}&pagination[page]=${currentPage_video}&populate=*`,
          
        })
      },
     
      providesTags: ['videoData']
    }),
 
  })
})
 
export const {
  useFetchGalleryDataQuery,
  useFetchPhotoGalleryDataQuery,
  useFetchVideoGalleryDataQuery
} = galleryDataApiCall;
 