import React from "react";
import { useParams } from "react-router-dom";
import { useFetchLeaderShipDetailDataQuery } from "../store/rtk/leaderShipDetails"; 
import { REACT_APP_BASE_BACKEND_URL } from "../config";
import link from "../assets/images/linkedin-in.svg";
import { Loader } from "../shared/Loader";
const LeaderShipDetailsPage = () => {
  const params = useParams();
const {isFetching,data}=useFetchLeaderShipDetailDataQuery({params})

  return (
    <section className="leader-ship-details-page mt-5">    
     {isFetching && <Loader/>}
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-3 col-md-3 col-12">
            <div className="left_img_led">
              <img src={REACT_APP_BASE_BACKEND_URL + data?.data?.attributes?.image?.data?.attributes?.url} alt="img" className="img-fluid w-100 border-image" />
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <div className="ledership_full_des">
              <h1 className="fs-48">{data?.data?.attributes?.name}</h1>
              <div className="social-icon d-flex justify-content-between mt-3 designation_content">
              <h3 className="fs-24">{data?.data?.attributes?.designation}</h3>
              <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
      <img src={link} alt="linkedin" className="img-fluid" />
    </a>
            </div>
            <div className="full_desc" dangerouslySetInnerHTML={{ __html:data?.data?.attributes?.description}}>            
           </div>
            </div>
        
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeaderShipDetailsPage;
