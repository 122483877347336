import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const contactLoctionApiCall = createApi({
  reducerPath: "contactLoctionApiCall",
  tagTypes: ["contactLocationData"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchcontactLocationData: build.query({
      query: (params={}) => {
       // console.log(params,"this is params");
        return {
          url: `/api/${params?.subPath}?populate=*`,
        };
      },

      providesTags: ["contactLocationData"],
    }),

  }),
});

export const {
  useFetchcontactLocationDataQuery,
} = contactLoctionApiCall;
