import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
 
 
export const nopplInvesterRelationApiCall = createApi({
  reducerPath: 'nopplInvesterRelationApiCall',
  tagTypes: [' nopplInvesterRelationData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchNopplIvestorRelationData: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/noppl-investor?populate[Noppl_investor_file][populate]=File`,
       
        })
      },
     
      providesTags: ['nopplInvesterRelationData']
    }),

 
  })
})
 
export const {
  useFetchNopplIvestorRelationDataQuery
} = nopplInvesterRelationApiCall;
 