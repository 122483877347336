import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const PrivacyPolicyApiCall = createApi({
  reducerPath: 'PrivacyPolicyApiCall',
  tagTypes: ['PrivacyPolicyPagesData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchPrivacyPolicyData: build.query({
      query: () => {
       
        return ({
          url: `/api/privacy-policy`,
        //  params: params,
        })
      },
      providesTags: ['PrivacyPolicyPagesData']
    }),
// for log
  })
})
export const {
   useFetchPrivacyPolicyDataQuery
} = PrivacyPolicyApiCall;
 