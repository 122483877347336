import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
 
 
export const homeApiCall = createApi({
  reducerPath: 'homeApiCall',
  tagTypes: ['homeData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchHomeData: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/home?populate=[HomePage][populate]=image&populate[events][populate]=event_image&populate[aboutus_section][populate]=image&populate[people_planet_processes][populate]=Image,Logo&populate[why_nucleus_office_parks][populate]=image&populate[news][populate]=gallery&[populate]=portfolio_counts`,
        //  params: params,
        })
      },
     
      providesTags: ['homeData']
    }),
// for log
 
fetchPortifoliCount: build.query({
  query: (params = {}) => {
    return ({
      url: `/api/portfolio-counts`,
    //  params: params,
    })
  },
 
  providesTags: ['homeData']
}),
//for 
fetchPortifoliLocationBanner: build.query({
  query: (params = {}) => {
    return ({
      url: `/api/portfolio-location-banners?populate=*`,
    //  params: params,
    })
  },
 
  providesTags: ['homeData']
}),

  })
})
 
export const {
  useFetchHomeDataQuery,useFetchPortifoliCountQuery,useFetchPortifoliLocationBannerQuery
} = homeApiCall;
 