import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const NewsApiCall = createApi({
  reducerPath: 'NewsApiCall',
  tagTypes: ['portfolioPagesData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchNewsData: build.query({
      query: ({currentPage,pageSize}) => {
       // console.log(currentPage,pageSize)
        return ({
          url: `/api/news?sort[0]=id:desc&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage}&populate=*`,
        //  params: params,
        })
      },
      providesTags: ['portfolioPagesData']
    }),
// for log
  })
})
export const {
   useFetchNewsDataQuery
} = NewsApiCall;
 