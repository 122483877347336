import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import  store  from './store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/swiper-bundle.css";
import "../src/assets/css/style.css"
import "../src/index.css"
import ScrollToTop from "react-scroll-to-top";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
    <BrowserRouter  >
    <Provider store={store}>
      <App />
    </Provider>
    <ScrollToTop smooth  className='button'/>
    </BrowserRouter>  
);



