import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
 
 
export const PortfolioExoraBusinessParkApiCall = createApi({
  reducerPath: 'PortfolioExoraBusinessParkApiCall',
  tagTypes: ['portfolioPagesData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
      
    fetchPortfolioExoraBusinessParkData: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/exora-business-park?populate[Salient_feature][populate]=images&populate[Excellence][populate]=images&populate[Gallery][populate]=images&populate[Sustainability][populate]=images&populate[Location_map][populate]&populate[Overview_content][populate]=image`,
        //  params: params,
        })
      },
     
      providesTags: ['portfolioPagesData']
    }),

    fetchPortfolioCessnaBusinessParkData: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/cessna-business-park?populate[Salient_feature][populate]=images&populate[Excellence][populate]=images&populate[Gallery][populate]=images&populate[Sustainability][populate]=images&populate[Location_map][populate]&populate[Overview_content][populate]=image`,
        //  params: params,
        })
      },
     
      providesTags: ['portfolioPagesData']
    }),
// for log
 
  })
})


 
export const {
   useFetchPortfolioExoraBusinessParkDataQuery,
   useFetchPortfolioCessnaBusinessParkDataQuery
} = PortfolioExoraBusinessParkApiCall;
 