import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
  
export const investorDataApiCall = createApi({
  reducerPath: 'investorDataApiCall',
  tagTypes: ['investorData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchInvestorData: build.query({
      query: () => {
        return ({
          url: `/api/investor?populate=*`,
          
        })
      },
     
      providesTags: ['investorData']
    }),


 
  })
})
 
export const {
  useFetchInvestorDataQuery,
 
} =investorDataApiCall;
 