import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
  
export const esgReportPagesApiCall = createApi({
  reducerPath: 'esgReportPagesApiCall',
  tagTypes: ['esgReportPagesData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchEsgReportPagesData: build.query({
      query: () => {
        return ({
          url: `/api/sustainability-reports?sort[0]=id:desc&populate=*`,
        })
      },
     
      providesTags: ['esgReportPagesData']
    }),
 
  })
})
 
export const {
  useFetchEsgReportPagesDataQuery
} = esgReportPagesApiCall;
 