// TabMenu.js
import React, { useState,useEffect } from 'react';
import pdfIcon from "../../assets/images/file-pdf-light.svg"
import { REACT_APP_BASE_BACKEND_URL } from '../../config';
const TabMenu = ({data, titel }) => {
  const initialTab = data?.Investor_tab?.length > 0 
  ? data.Investor_tab[data.Investor_tab.length - 1].Finance_year 
  : '';
  const [activeTab, setActiveTab] = useState(initialTab);
  const sanitizeId = (str) => str.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
  const handleTabClick = (year) => {
    setActiveTab(year);
  };

  useEffect(() => {
    const lastIndex = data?.Investor_tab?.length - 1;
    if (lastIndex >= 0) {
      setActiveTab(data.Investor_tab[lastIndex].Finance_year);
    }
  }, [data]);

  const groupByCategory = (items) => {
    return items.reduce((acc, item) => {
      const category = item?.Category;
      if (!category) return acc;
  
      if (!acc[category]) {
        acc[category] = [];
      }
  
      acc[category].push(item);
      return acc;
    }, {});
  };

  return (
    <>
 
  
      <div className="row investor_relations_tab inv_table board_table">
        <div className="col-lg-2">
          <div className='title_financial_year mt-3'>
            <h2>{titel}</h2>
          </div>
        </div>

        <div className='col-lg-10'>
          <nav>
            <div
              className="nav nav-tabs gallery_tabs mb-3"
              id="nav-tab"
              role="tablist"
            >
              {data?.Investor_tab?.slice().reverse().map((item, i) => {
                const sanitizedId = sanitizeId(item.Finance_year);
                return (
                  <button
                    key={i}
                    className={`nav-link ${activeTab === item.Finance_year ? 'active' : ''}`}
                    id={`nav-${sanitizedId}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#nav-${sanitizedId}`}
                    type="button"
                    role="tab"
                    aria-controls={`nav-${sanitizedId}`}
                    aria-selected={activeTab === item.Finance_year}
                    onClick={() => handleTabClick(item.Finance_year)}
                  >
                    {item.Finance_year}
                  </button>
                );
              })}
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
          {data?.Investor_tab?.map((item, indx) => {
        const sanitizedId = sanitizeId(item.Finance_year);
        const groupedItems = groupByCategory(item?.Investor_file || []);

        return (
          <div
            key={indx}
            className={`tab-pane fade ${activeTab === item.Finance_year ? 'show active' : ''}`}
            id={`nav-${sanitizedId}`}
            role="tabpanel"
            aria-labelledby={`nav-${sanitizedId}-tab`}
          >
            {Object.entries(groupedItems).map(([category, items]) => (
              <div key={category}>
                <div className='quarterly_data_heading'>
                  <h2 className='file-title'>{category}</h2>
                </div>
                <div className='row gy-5 mt-4'>
                  {items.map((item1, i) => (
                    <div className='col-lg-4 quarterly_data' key={i}>
                      <div className='pdf_icon'>
                        <img src={pdfIcon} alt="icon" className='file-icon' />
                      </div>
                      <a href={ item1?.File?.data !== null ? REACT_APP_BASE_BACKEND_URL + item1?.File?.data?.attributes?.url:"#"} target='_blank'>
                        <p>{item1?.Title}</p>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
      })}
</div>

        </div>
      </div>

    </>
  );
};

export default TabMenu;
