import React from "react";
import Slider from "react-slick";
import { REACT_APP_BASE_BACKEND_URL } from "../config";
import { useFetchBannerQuery } from "../store/rtk/banner";
import { Loader } from "../shared/Loader";
export default function Banner({ urlName, hideUnderline = false }) {
  const { isFetching, data } = useFetchBannerQuery(urlName);

  const settings = {
    dots: data?.data?.length > 1 ? true : false,
    infinite: data?.data?.length > 1 ? true : false,
    speed: 700,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="top_slider">
      {isFetching && <Loader />}
      {data?.data?.length > 1 ? (
        <Slider {...settings}>
          {data?.data?.map((banner) => (
            <div key={banner.id}>
              <div className="top_slider_image">
                <img
                  src={`${
                    REACT_APP_BASE_BACKEND_URL +
                    banner?.attributes?.banner_image?.data?.attributes?.url
                  }`}
                  className="img-fluid"
                  alt={banner?.attributes?.banner_image?.data?.attributes?.url}
                />
             
                <div className="container">
                  {!hideUnderline && (
                    <div className="top_heading">
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: banner?.attributes?.title,
                        }}
                      ></h3>
                      <div className="bottom_con">
                        {/* {banner?.attributes?.description?.map((desc, index) => ( */}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: banner?.attributes?.description,
                          }}
                        />
                        {/* ))} */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div>
          <div className="top_slider_image">
            <img
              src={`${
                REACT_APP_BASE_BACKEND_URL +
                data?.data[0]?.attributes?.banner_image?.data?.attributes?.url
              }`}
              className="img-fluid"
              alt={data?.data[0].attributes.title}
            />
            <div className="container">
              {!hideUnderline && (
                <div className="top_heading">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: data?.data[0]?.attributes?.title,
                    }}
                  ></h3>
                  <div className="bottom_con">
                    {/* {data?.data[0]?.attributes?.description?.map((desc, index) => ( */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data?.data[0]?.attributes?.description,
                      }}
                    />
                    {/* ))} */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
