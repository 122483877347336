import React from "react";
import { useFetchCommunicationsDataQuery } from '../store/rtk/Communications';
import Banner from "./Banner";
import { Link } from "react-router-dom";
import {  REACT_APP_BASE_BACKEND_URL } from '../config';
import { Loader } from "../shared/Loader";

export const Communications = () => {
  const { isFetching, data } = useFetchCommunicationsDataQuery()
  

  return (
    <>
      {/* banner */}
      {isFetching && <Loader />}
      <section className="banner_image_common">
        <Banner urlName={"communications"} hideUnderline={true} />
      </section>
      {/* banner */}

      {/* Events */}
      <section className="news" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row gx-md-5">
            {data?.data.map((item, index) => {
              const url=item?.attributes?.zip_file?.data?.attributes?.name;
                const fileName = url;
              const newFileName = fileName.replace(/\.zip$/i,"");
              return(
                <div className="col-lg-6 col-md-6 col-12 ">
                <Link to={item?.attributes.Document_type === "PDF" ? `${REACT_APP_BASE_BACKEND_URL}${item?.attributes?.PDF_file?.data?.attributes?.url}` : `${REACT_APP_BASE_BACKEND_URL}/${`uploads/${newFileName}/index.html`}`}
                  target="_blank"
                  className="inv_data"
                >
                  <div className="commitem">
                    <div className="comm-title">
                      <i className="bi bi-file-earmark-text"></i>
                      {item?.attributes?.Title}
                    </div>
                    <div className="btncol">

                      <i className="bi bi-arrow-right right"></i>


                    </div>
                  </div>
                </Link>
              </div>
              );
            })}


          </div>
        </div>
      </section>
      {/* Events */}
    </>
  );
};
