import React from 'react';
import { REACT_APP_BASE_BACKEND_URL } from "../config"
import { useFetchLogoQuery } from '../store/rtk/menu'
export default function Logo() {

    const error=null
    const { data } = useFetchLogoQuery()
    return (
        <div className="col-lg-2">
            <div className="footer_img">
                {error ? (
                    <p>Error: {error}</p>
                ) : data ? (
                    <img
                        src={`${REACT_APP_BASE_BACKEND_URL + data?.data[0]?.attributes?.LogoName?.data?.attributes?.url}`} // Adjust the URL path as per your JSON
                        alt={data?.data[0].attributes?.LogoName?.data?.attributes?.name}
                        className="img-fluid"
                        width={data?.data[0]?.attributes?.LogoName?.data?.attributes?.width}
                        height={data?.data[0]?.attributes?.LogoName?.data?.attributes?.height}
                    />
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
}
