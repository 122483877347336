import React, { useState } from "react";
import TeamMember from "./TeamMember";
import { useFetchAboutUsDataQuery } from "../store/rtk/aboutus";
import "bootstrap/dist/css/bootstrap.min.css";
import { REACT_APP_BASE_BACKEND_URL } from "../config";
import { Link } from "react-router-dom";
import { Loader } from "../shared/Loader";
import { CSSTransition } from "react-transition-group";
const Team = () => {
  const [activeMember, setActiveMember] = useState(null);
  const { isFetching, data } = useFetchAboutUsDataQuery();
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="container">
      {isFetching && <Loader />}
      <div className="row ledership_card_row">
        {data?.data?.attributes?.leader_ships?.data?.map((member, id) => (
          <TeamMember
            key={member.name}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            name={member?.attributes?.name}
            title={member?.attributes?.designation}
            description={member?.attributes?.short_description}
            detaiDescription={member?.attributes?.description}
            id={member?.id}
            readMoreBtn="Read More"
            activeMember={activeMember}
            imgSrc={
              REACT_APP_BASE_BACKEND_URL +
              member?.attributes?.image?.data?.attributes?.url
            }
            imgAlt={
              REACT_APP_BASE_BACKEND_URL +
              member?.attributes?.image?.data?.attributes?.url
            }
            onShowDetails={setActiveMember}
            isActive={
              activeMember && activeMember.name === member?.attributes?.name
            }
          />
        ))}
      </div>
      <CSSTransition
        in={isVisible}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div className="row hide_one_small_device">
          <div className="col-lg-12">
            <div className="card border-0">
              <div className="card-body px-0">
                <p
                  className="card-text text-start fs-17"
                  dangerouslySetInnerHTML={{
                    __html: activeMember?.description,
                  }}
                />
                {activeMember?.detaiDescription && (
                  <div className="read_more">
                    <Link to={`/leader-ship-details-page/${activeMember?.id}`}>
                      <button className="btn btn-dark">Read More</button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Team;
