import { useNavigate } from 'react-router-dom';

export const DynamicContent = ({ htmlString, index }) => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    const target = e.target.closest('a');
    if (target && target.getAttribute('href')) {
      if (e.ctrlKey || e.metaKey || e.shiftKey || e.button === 1) {
        return;
      }
      e.preventDefault();
      const href = target.getAttribute('href');
      navigate(href);

    }
  };


  return (
    <div className={
      index === 0
        ? "tab-pane fade show  active"
        : "tab-pane fade"
    }
      id={htmlString?.Title?.replace(
        /\s+/g,
        "-"
      ).toLowerCase()}
      role="tabpanel"
      aria-labelledby="nav-one-tab"
      dangerouslySetInnerHTML={{ __html: htmlString?.Content }}
      onClick={handleClick}
    />
  );
};
