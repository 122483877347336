import Modal from "react-bootstrap/Modal";
import { Loader } from "../../shared/Loader";

export const Modals = ({ show, setShow, data, location, loading }) => {
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal_section"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="fw-700 head_title">{data?.Tooltip}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <Loader />}
          <iframe
            title="tit"
            width="100%"
            height="480"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            src={`https://maps.google.it/maps?q=${
              location?.Tooltip + location?.Address
            }&output=embed`}
            style={{ visibility: loading ? "hidden" : "visible" }}
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};
