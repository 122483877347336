import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const oneBkcPagesApiCall = createApi({
  reducerPath: "oneBkcPagesApiCall",
  tagTypes: ["oneBkcPagesData"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchOneBkcPagesData: build.query({
      query: () => {
        return {
          url: `/api/onebkc?populate[Salient_feature][populate]=images&populate[Onebkc_excellence][populate]=images&populate[Onebkc_gallery][populate]=images&populate[Onebkc_sustainability][populate]=images&populate[Location_map][populate]&populate[Overview_content][populate]=image`,
        };
      },

      providesTags: ["oneBkcPagesData"],
    }),

    fetchOneInternationalCenterPagesData: build.query({
      query: () => {
        return {
          url: `/api/one-international-center?populate[Salient_feture][populate]=images&populate[Excellence][populate]=images&populate[Gallery][populate]=images&populate[Sustainability][populate]=images&populate[Location_map][populate]&populate[Overview_content][populate]=image`,

        };
      },
      providesTags: ["oneBkcPagesData"],
    }),
    fetchOneWorldCenterPagesData: build.query({
      query: () => {
        return {
          url: `/api/one-world-center?populate[Salient_feature][populate]=images&populate[Excellence][populate]=images&populate[Gallery][populate]=images&populate[Sustainability][populate]=images&populate[Location_map][populate] &populate[Overview_content][populate]=image`,
        };
      },
      providesTags: ["oneBkcPagesData"],
    }),
    fetchPrimabayPagesData: build.query({
      query: () => {
        return {
          url: `/api/prima-bay?populate[Salient_feture][populate]=images&populate[Excellence][populate]=images&populate[Gallery][populate]=images&populate[Sustainability][populate]=images&populate[Location_map][populate] &populate[Overview_content][populate]=image`,
        };
      },
      providesTags: ["oneBkcPagesData"],
    }),
    fetchOneUnityCenterData: build.query({
      query: () => {
        return {
          url: `/api/one-unity-center?populate[Salient_feature][populate]=images&populate[Excellence][populate]=images&populate[Gallery][populate]=images&populate[Sustainability][populate]=images&populate[Location_map][populate] &populate[Overview_content][populate]=image`,
        };
      },
      providesTags: ["oneBkcPagesData"],
    }),
  }),
});

export const {
  useFetchOneBkcPagesDataQuery,
  useFetchOneInternationalCenterPagesDataQuery,
  useFetchOneWorldCenterPagesDataQuery,
  useFetchPrimabayPagesDataQuery,
  useFetchOneUnityCenterDataQuery,
} = oneBkcPagesApiCall;
