import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
 
 
export const PortfolioConceptTechParkApiCall = createApi({
  reducerPath: 'PortfolioConceptTechParkApiCall',
  tagTypes: ['portfolioPagesData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
      
    fetchPortfolioConceptTechParkData: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/concept-tech-park?populate[Salient_feature][populate]=images&populate[Excellence][populate]=images&populate[Gallery][populate]=images&populate[Sustainability][populate]=images&populate[Location_map][populate]&populate[Overview_content][populate]=image`,
        //  params: params,
        })
      },
     
      providesTags: ['portfolioPagesData']
    }),

    fetchPortfolioOneQubeData: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/one-qube?populate[Salient_feature][populate]=images&populate[Excellence][populate]=images&populate[Gallery][populate]=images&populate[Sustainability][populate]=images&populate[Location_map][populate]&populate[Overview_content][populate]=image`,
        //  params: params,
        })
      },
     
      providesTags: ['portfolioPagesData']
    }),
// for log
 
  })
})


 
export const {
   useFetchPortfolioConceptTechParkDataQuery,
   useFetchPortfolioOneQubeDataQuery
} = PortfolioConceptTechParkApiCall;
 