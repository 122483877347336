import React from "react";

import { useFetchBannerQuery } from "../store/rtk/banner";
import { REACT_APP_BASE_BACKEND_URL } from "../config";
import { Link } from "react-router-dom";
export const SubBannerDetails = ({ urlName }) => {
  const { data } = useFetchBannerQuery(urlName);
  
  return (
    <>
      <section className="bkc-hero">
        <div className="container">
          <div className="row ">
            <div className="col-sm-8 col-positioned p-0">
              <img
              alt="impath"
                src={`${
                  REACT_APP_BASE_BACKEND_URL +
                  data?.data?.[0]?.attributes?.banner_image?.data?.attributes
                    ?.url
                }`}
              />

              <div className="row">
                <div className="col-sm-4">
                  <div className="content-box position-absolute top-50 start- translate-middle">
                    {data?.data?.[0]?.attributes?.title || data?.data?.[0]?.attributes?.description ?<React.Fragment>
                    <h2 className="title text-white fw-bold">
                      {data?.data?.[0]?.attributes?.title}
                    </h2>
                    <p className="para text-white fs-20">
                      {data?.data?.[0]?.attributes?.description}
                    </p>
                    </React.Fragment>:null}
                    
                 
                    <div className="btn-wrapper">
                      <button className="btn btn-enquire text-white">
                        <Link to="/contact"> ENQUIRE NOW</Link>
                      </button>
                      <button className="btn btn-gallery">
                        <Link to="/media/gallery">Gallery</Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="one-bkc">
              <img
              alt="imglink"
                src={`${
                  REACT_APP_BASE_BACKEND_URL +
                  data?.data?.[0]?.attributes?.Logo?.data?.attributes
                    ?.url
                }`}
              />
                {/* <img src={onebkc2} alt="one-bkc.png" className="img-fluid" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
