import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
  
export const esgPagesApiCall = createApi({
  reducerPath: 'esgPagesApiCall',
  tagTypes: ['esgPagesData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchEsgPagesData: build.query({
      query: () => {
        return ({
          url: `api/esg?populate[Esc_planet_process][populate]&populate[Esc_framework][populate]&populate[Esg_gresb][populate]&populate[Esg_considerations][populate]&populate[Esg_focus_areas][populate]&populate[Eng_community][populate]&populate[Esg_sdgs][populate]&populate[Esg_governance][populate]&populate[Esg_achi_tab][populate]&populate[Esg_recognition][populate]&populate[Esg_sustain_tab][populate]&populate[Esg_sustain_report][populate]=image`,
        })
      },
     
      providesTags: ['esgPagesData']
    }),
 
  })
})
 
export const {
  useFetchEsgPagesDataQuery
} = esgPagesApiCall;
 