import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
  
export const blogDataApicall = createApi({
  reducerPath: 'blogDataApicall',
  tagTypes: ['blogData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchBlogData: build.query({
      query: ({currentPage,pageSize}) => {
        return ({       
        url:`/api/blogs?sort[0]=id:desc&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage}&populate=*`         
        })
      },    
      providesTags: ['blogData']
    }),
    fetchBlogDataDetail: build.query({
      query: ({params}) => {     
        return ({
          url: `/api/blogs/${params.id}?[populate]=Image` ,              
        })
      },
     
      providesTags: ['blogData']
    }),
    fetchBlogDataAll: build.query({
      query: () => {     
        return ({
          url: `/api/blogs?[populate]=*` ,              
        })
      },
     
      providesTags: ['blogData']
    }),

 
  }),

})
 
export const {
  useFetchBlogDataDetailQuery,useFetchBlogDataQuery,useFetchBlogDataAllQuery
 
} =blogDataApicall;
 