import React from "react";

import { REACT_APP_BASE_BACKEND_URL } from "../config";
import { useFetchBannerQuery } from "../store/rtk/banner";
function PortfolioBanner({ urlName }) {
  const { data } = useFetchBannerQuery(urlName);
 
  return (
    <section className="portfolio_section">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-4 p-0 position-relative">
          <div className="portfolio_left">
            <h3>{data?.data?.[0]?.attributes?.title}</h3>
          </div>
        </div>

        <div className="col-sm-8 p-0">
          <div className="portfolio_right">
            <img
              src={`${
                REACT_APP_BASE_BACKEND_URL +
                data?.data?.[0]?.attributes?.banner_image?.data?.attributes
                  ?.url
              }`}
              alt="portfolio"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default PortfolioBanner;
