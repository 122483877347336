import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { List } from './List';

export const Accordions = ({ title, items }) => {
  const [activeKey, setActiveKey] = useState(null);

  return (
    <div className='mt-3'>
      <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{title}</Accordion.Header>
          <Accordion.Body>
            {/* Render List component */}
            {title === "Contact" ? "" : <List title={title} items={items} />}

            {/* Map through items */}
            {items.map((item) => (
              <div key={item.id} className="accordion-item">
                {item.content}
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
