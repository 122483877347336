import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
  
export const leaderShipDetailApiCall = createApi({
  reducerPath: 'leaderShipDetailApiCall',
  tagTypes: ['leaderShipDetailData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchLeaderShipDetailData: build.query({
      query: ({params}) => {     
        return ({      
        url:`/api/leader-ships/${params.id}?[populate]=image`          
        })
      },    
      providesTags: ['leaderShipDetailData']
    }),


 
  })
})
 
export const {
  useFetchLeaderShipDetailDataQuery,
 
} =leaderShipDetailApiCall;
 