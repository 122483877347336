import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
 
 
export const contactUsApiCall = createApi({
  reducerPath: 'contactUsApiCall',
  tagTypes: ['contactus'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    addContactForm: (build).mutation({
      invalidatesTags: ["contactus"],
      query: (data) => ({ 
        url: `/api/contacts/`,
        body: data,
        method: 'POST'
      }),
//transformResponse
      transformErrorResponse: (error) => {
        console.log('Add contact Error Log ====>', error)
        // Swal.fire(error.data, `Status Code: ${error.status}`, 'error');
        return error;
      }
    }),
 
  })
})
 
export const {
  useAddContactFormMutation
} = contactUsApiCall;
 