import React from 'react';
import { Link } from 'react-router-dom';

export const List = ({ items, title }) => {
  return(
  <ul className="footer_toppadding menu_item">
    {items?.slice(0,title==="Portfolio" ? 5 : items.length).map((item) => {
      return(
        <li key={item.id}>
        <Link to={title==="Portfolio" ? item?.[0].parent.path : item?.attributes?.Page_link}>{title==="Portfolio" ? item?.[0].parent.title : item?.attributes?.QuicklinkName || item?.attributes?.PortfolioName}</Link>
        </li>
      )
    })}
  </ul>
);
}


