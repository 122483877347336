import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
 
 
export const portfolioPagesApiCall = createApi({
  reducerPath: 'portfolioPagesApiCall',
  tagTypes: ['portfolioPagesData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchPortfolioPagesData: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/mumbais?populate=*`,
        //  params: params,
        })
      },
     
      providesTags: ['portfolioPagesData']
    }),

    fetchPortfolioGurugramData: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/gurugrams?populate=*`,
        //  params: params,
        })
      },
     
      providesTags: ['portfolioPagesData']
    }),

    fetchPortfolioBengaluruData: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/bengalurus?populate=*`,
        //  params: params,
        })
      },
     
      providesTags: ['portfolioPagesData']
    }),

    
// for log
 
  })
})


 
export const {
  useFetchPortfolioPagesDataQuery,
  useFetchPortfolioGurugramDataQuery,
  useFetchPortfolioBengaluruDataQuery
  
} = portfolioPagesApiCall;
 