import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const portfolioKosmoOneApiCall = createApi({
  reducerPath: "portfolioKosmoOneApiCall",
  tagTypes: ["portfolioPagesData"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchPortfolioKosmoOneData: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/kosmo-one?populate[Salient_feature][populate]=images&populate[Excellence][populate]=images&populate[Gallery][populate]=images&populate[Sustainability][populate]=images&populate[Location_map][populate]&populate[Overview_content][populate]=image`,
        //  params: params,
        })
      },

      providesTags: ["portfolioPagesData"],
    }),
    // for log
  }),
});

export const { useFetchPortfolioKosmoOneDataQuery } = portfolioKosmoOneApiCall;
