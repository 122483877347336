import React from "react";
import { useFetchCareerDataQuery } from "../store/rtk/Career ";
import Banner from "./Banner";
import { Loader } from "../shared/Loader";

export const Career = () => {
  const {isFetching, data } = useFetchCareerDataQuery();
 
  return (
    <>
     {isFetching && <Loader/>}
      {/* banner */}
      <section className="banner_image_common">
        <Banner urlName={"career"} />
      </section>
 
      {/* banner */}

      {/* Carrers */}
      <section className="news" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div
            dangerouslySetInnerHTML={{
              __html: data?.data?.attributes?.Content,
            }}
          ></div>
        </div>
      </section>
      {/* Carrers */}
    </>
  );
};
