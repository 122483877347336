import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import CountUp from "react-countup";
import { Helmet } from 'react-helmet';
import { REACT_APP_API_URL, REACT_APP_BASE_BACKEND_URL } from "../config";
import { Link } from "react-router-dom";
import {
  useFetchHomeDataQuery, useFetchPortifoliLocationBannerQuery
} from "../store/rtk/commanHome";
import { useFetchMenuQuery } from "../store/rtk/menu";
import { Loader } from "../shared/Loader";

export default function ContentDetail() {

  const [portfolio, setportfolio] = useState([]);
  const { isFetching, data } = useFetchHomeDataQuery();
  const { data: hierarchy } = useFetchMenuQuery();
  const { data: locationBannerData } = useFetchPortifoliLocationBannerQuery();
  var locationArray = {}
  locationBannerData?.data.map(item => {
    const location = item?.attributes?.Location;
    const url = item?.attributes?.Image?.data?.attributes?.url;
    locationArray[location] = url;
  });
  useEffect(() => {
    menuOrdring(hierarchy);
  }, [hierarchy]);
  const menuOrdring = (hierarchy) => {
    const filterdData = hierarchy?.find((item) => item.title === "Portfolio");
    let myArray = [];
    filterdData?.children?.forEach((item, index) => {
      myArray.push(item.children);
    });
    setportfolio(myArray);
  };



  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };



  const settingsForPortfolio = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  var settingsofficepark = {
    dots: false,
    infinite: data?.data?.attributes?.news?.data.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    margin: 100,

    centerPadding: "5000px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };
  const createSlider = (arr) => {
    let cat = arr[0];
    return (
      <div className="location_link">
        <h3>{cat.parent.title}</h3>
        {arr.map((item) => {
          return (
            <span key={item.id}>
              <img
                src={`${REACT_APP_API_URL}/assets/Svg/angle-right-light.svg`}
                alt="alterim"
              />{" "}
              <Link to={item.path}>{item.title}</Link>
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {isFetching && <Loader />}
      <Helmet>
        <title>This is home page</title>
        <meta
          name="description"
          content="this is home page of nuclous park"
        />
      </Helmet>
      {data?.data?.attributes?.aboutus_section?.data?.attributes ? (
        <section
          className="main_top aboutus"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="container">
            <div className="row column_reverse gx-md-5 gy-5">
              <div className="col-lg-6 col-md-5">
                <div className="about_image about_left">
                  <img
                    src={
                      REACT_APP_BASE_BACKEND_URL +
                      data.data.attributes.aboutus_section.data.attributes.image
                        .data.attributes.url
                    }
                    alt="aboutus"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-7 d-flex align-items-center">
                <div className="about_right">
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        data.data.attributes.aboutus_section.data.attributes
                          .short_description,
                    }}
                  ></p>

                  <div className="aboutbtn">
                    <Link to="/aboutus">
                      <button className="btn btn-dark btn-title">
                        Know More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/* <!-- portfolio section --> */}
      {data?.data?.attributes?.portfolio_counts || data?.data ? (
        <section
          className="main_top"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-12 col-md-12 d-lg-flex align-items-center p-0 portfolio">
                <div className="offset-md-3">
                  <div className="portfolio_inner">
                    <div className="portfolio_heading">
                      <h3>Our Office Portfolio</h3>
                      <p>
                        We take pride in our wide array of commercial <br />
                        assets located across India.
                      </p>
                    </div>
                    <div className="port_sec">
                      <div className="row">
                        <div className="col-6">
                          <div className="portfolio_icon">
                            <div className="img_log">
                              <img
                                src={`${REACT_APP_API_URL}/assets/Svg/footprint.svg`}
                                alt="imgr"
                                className="img-fluid"
                              />
                            </div>
                            <div className="counters">
                              <h3>
                                <span
                                  className="counter"
                                  data-countto="19.6"
                                  data-duration="8000"
                                ></span>
                                <span className="stat-suffix">
                                  <CountUp
                                    end={data?.data?.attributes?.portfolio_counts?.data[0]?.attributes?.Footprint}
                                    decimals={1} // Set the number of decimal places
                                  />
                                  mn
                                </span>

                              </h3>

                              <p>Sqft of Footprint</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="portfolio_icon">
                            <div className="img_log">
                              <img
                                src={`${REACT_APP_API_URL}/assets/Svg/corporate-tenants.svg`}
                                alt="imge"
                                className="img-fluid"
                              />
                            </div>
                            <div className="counters">
                              <h3>
                                <span
                                  className="counter"
                                  data-countto="200"
                                  data-duration="8000"
                                ></span>
                                <span className="stat-suffix">
                                  <CountUp
                                    end={
                                      data?.data?.attributes?.portfolio_counts
                                        ?.data[0]?.attributes?.tenants
                                    }
                                  />
                                  +
                                </span>
                              </h3>

                              <p>Corporate Tenants</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="portfolio_icon">
                            <div className="img_log">
                              <img
                                src={`${REACT_APP_API_URL}/assets/Svg/commercial-assests.svg`}
                                alt="imgr"
                                className="img-fluid"
                              />
                            </div>
                            <div className="counters">
                              <h3>
                                <span
                                  className="counter"
                                  data-countto="16"
                                  data-duration="8000"
                                >
                                  <CountUp
                                    end={
                                      data?.data?.attributes?.portfolio_counts
                                        ?.data[0]?.attributes?.Assets
                                    }
                                  />

                                  {/* {
                                    data?.data?.attributes?.portfolio_counts
                                      ?.data[0]?.attributes?.Assets
                                  } */}
                                </span>
                              </h3>

                              <p>Grade A Commercial Parks</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="portfolio_icon">
                            <div className="img_log">
                              <img
                                src={`${REACT_APP_API_URL}/assets/Svg/park-users.svg`}
                                alt="imgr"
                                className="img-fluid"
                              />
                            </div>
                            <div className="counters">
                              <h3>
                                <span
                                  className="counter"
                                  data-countto="120"
                                  data-duration="8000"
                                ></span>
                                <span className="stat-suffix">
                                  <CountUp
                                    end={
                                      data?.data?.attributes?.portfolio_counts
                                        ?.data[0]?.attributes?.users
                                    }
                                  />
                                  {/* {
                                    data?.data?.attributes?.portfolio_counts
                                      ?.data[0]?.attributes?.users
                                  } */}
                                  k+
                                </span>
                              </h3>
                              <p>Park Users</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p-0">
                <div className="porftolio_slider">
                  <div className="slider_sec">

                    <Slider {...settingsForPortfolio}>
                      {portfolio.map((item, indx) => {
                        var title = item?.[0]?.parent?.title;
                        if (indx < portfolio.length - 1) {
                          return (
                            <div className="slider_sec" key={indx}>
                              <div className="slider__item image-container">
                                <img
                                  src={`${REACT_APP_BASE_BACKEND_URL}${locationArray?.[title]}`}
                                  alt={locationArray?.[title]}
                                />
                                <div className="overlay"></div>
                                <div className="portfolio_content" key={item.id}>
                                  {createSlider(item)}
                                </div>
                                <div className="slider__counter">
                                  {indx + 1}/{(portfolio.length - 1)}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      {/* portfoli section  */}

      {/* <!-- People, Planet & Processes section --> */}
      {data?.data?.attributes?.people_planet_processes?.data ? (
        <section
          className="main_top people-planet-sec"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="planet">
            <div className="planet_contnet">
              <div className="container">
                <div className="Planet_heading">
                  <h3>People, Planet & Processes</h3>
                </div>
              </div>
              <div className="container-fluid">
                <div className="planet_img">
                  <div className="container-fluid ">
                    <div className="row gx-5 gy-4 img_cen">
                      {data?.data?.attributes?.people_planet_processes?.data?.map(
                        (peopleItem, index) => {

                          return (
                            <div
                              className=" col-sm-6 col-lg-4 p-section"
                              key={index}
                            >
                              <div
                                className="planet_image"
                                style={{
                                  backgroundImage: `url(${REACT_APP_BASE_BACKEND_URL +
                                    peopleItem?.attributes?.Image?.data
                                      ?.attributes?.url
                                    })`,
                                }}
                              >

                                <div className="social-all">
                                  <div className="icn_img">
                                    <img
                                      src={`${REACT_APP_BASE_BACKEND_URL +
                                        peopleItem?.attributes?.Logo?.data
                                          ?.attributes?.url
                                        }`}
                                      alt="Card imag Logo"
                                      className="social-image img-fluid"
                                    />
                                  </div>

                                  <div
                                    className="icon_text"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        peopleItem?.attributes?.Description,
                                    }}
                                  ></div>
                                  <div className="btn_exp">
                                    <Link to="/esg">
                                      <button className="btn btn-info explore_link">
                                        Explore
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/* <!-- People, Planet & Processes section --> */}

      {/* <!-- Events section -->  */}
      {data?.data?.attributes?.events?.data ? (
        <section
          className="main_top event-sec"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="events">
                  <h3>Events</h3>
                </div>
                {/* <p className="events_para">
                  {data?.data?.attributes?.events?.data?.[0]?.attributes?.Title}
                </p> */}
              </div>
              <div className="col-lg-4">
                <div className="events_details">
                  <Link to="/media/events">
                    <button className="btn btn-info">View All Events</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row event_slider">
              <Slider {...settings}>
                {data?.data?.attributes?.events?.data?.map(
                  (eventItem, index) => {
                    return (
                      <div className="" key={index}>
                        <div className=" slider_img">
                          <img
                            className="img-fluid"
                            src={`${REACT_APP_BASE_BACKEND_URL +
                              eventItem?.attributes?.event_image?.data?.[0]
                                ?.attributes?.url
                              }`}
                            alt="Card imag"
                          />
                        </div>
                        <div className="card-body content_events">
                          <div className="location d-flex align-items-center">
                            <img
                              src={`${REACT_APP_API_URL}/assets/Svg/location-dot-light.svg`}
                              alt=""
                            />
                            <p className="card-text">
                              {eventItem?.attributes?.location}
                            </p>
                          </div>
                          <div className="event_txt">
                            <p>{eventItem?.attributes?.short_description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </Slider>
            </div>
            <div className="events_detail">
              <Link to="/media/events">
                <button className="btn btn-info">View All Events</button>
              </Link>
            </div>
          </div>
        </section>
      ) : null}
      {/* <!-- Events section --> */}

      {/* <!-- Nucleus Office section --> */}
      {data?.data?.attributes?.news?.data ? (
        <section
          className="nucleus main_top"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="backcolor">
            <div className="container">
              <div className="row">
                <div className="col-sm-8">
                  <div className="nuc_heading">
                    <h3>Nucleus Office Parks in the News</h3>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="nuc_btn">
                    <Link to="/media/news">
                      <button className="btn btn-info">Read All</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row align-itmes-center">
                <div className="nucleus_slider">
                  <Slider {...settingsofficepark}>
                    {data?.data?.attributes?.news?.data?.map(
                      (newsItem, index) => {
                        const newsDate = new Date(newsItem?.attributes?.news_date);
                        // Format the date
                        const options = { year: 'numeric', month: 'short', day: 'numeric' };
                        const formattedDate = newsDate.toLocaleDateString('en-US', options);
                        return (
                          <div className="col-lg-6 card-group" key={index}>
                            <div className="card flex-row rounded-0 office_slider">
                              <div className="card-image">
                                <img
                                  src={`${REACT_APP_BASE_BACKEND_URL +
                                    newsItem?.attributes?.gallery?.data?.[0]
                                      ?.attributes?.url
                                    }`}
                                  alt="imge"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="nuc_info card-content p-4">
                                <div className="nuc_data">
                                  <h3>{formattedDate}</h3>
                                  <div className="nuc_para">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: newsItem?.attributes?.news_content.length > 190 ? newsItem?.attributes?.news_content.substring(0, 190) + '...' : newsItem?.attributes?.news_content,
                                      }}
                                    ></p>
                                  </div>
                                  <div className="continue">
                                    <h2>
                                      <span className="me-2">
                                        <img
                                          src={`${REACT_APP_API_URL}/assets/Svg/circle-plus-solid.svg`}
                                          alt="imgr"
                                        />
                                      </span>
                                      <a
                                        href={newsItem?.attributes?.news_url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Continue Reading
                                      </a>
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Slider>
                </div>
              </div>
              <div className="nuc_btn2">
                <Link to="/media/news">
                  <button className="btn btn-info">Read All</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      {/* <!-- Nucleus Office section --> */}
      {data?.data?.attributes?.why_nucleus_office_parks?.data ? (
        <section
          className="Parks main_top"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="container">
            <div className="nucleus_office">
              <h3>Why Nucleus Office Parks</h3>
            </div>
            <div className="col-lg-12">
              <div className="testimonial_slider">
                <div className="testimonials quote">
                  <Slider {...settings}>
                    {data?.data?.attributes?.why_nucleus_office_parks?.data?.map(
                      (whyItem, index) => (
                        <div key={index} className="testimonials quote">
                          <div className="quote_svg">
                            <img
                              src={`${REACT_APP_API_URL}/assets/Svg/quote-left-solid.svg`}
                              alt="imgw"
                              className="img-fluid"
                            />
                          </div>
                          <div className="testimonials_content">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: whyItem?.attributes?.Description,
                              }}
                            ></div>
                          </div>
                          <div className="testimonials_image">
                            <img
                              src={`${REACT_APP_BASE_BACKEND_URL +
                                whyItem?.attributes?.image?.data?.[0]
                                  ?.attributes?.url
                                }`}
                              alt="imgr"
                              className="img-fluid"
                            />
                            <div className="t_contnet">
                              <h3>{whyItem?.attributes?.name}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    whyItem?.attributes?.short_description,
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
}
