
export const Loader = () => {
    return (
        <div className="loader_bg">
            <div className="loader_item">

            </div>
        </div>

    );
}