import React from "react";
import { useFetchPrivacyPolicyDataQuery } from "../store/rtk/PrivacyPolicy";
import Banner from "./Banner";
import { Loader } from "../shared/Loader";

const PrivacyPolicy = () => {
  const { isFetching, data } = useFetchPrivacyPolicyDataQuery();

 
  return (
<>
    <section className="banner_image_common">
    {isFetching && <Loader/>}
    <Banner urlName={"privacy-policy"} />
    </section>
    <section className="">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
           
            <div className="privacy_policy"  dangerouslySetInnerHTML={{
                          __html:
                          data?.data?.attributes?.Content,
                        }}>
         
           </div>
          </div>
        </div>
      </div>
    </section>
    </> );
};

export default PrivacyPolicy;
