import React from "react";
import Banner from "./Banner";
import {DynamicContent} from "./DynamicContent"


import { useFetchInvestorDataQuery } from "../store/rtk/investor";
import { Loader } from "../shared/Loader";

const Investors = () => {
  const {isFetching,  data } = useFetchInvestorDataQuery();

  return (
    <>
     {isFetching && <Loader/>}
      {/* banner */}
      <section className="banner_image_common">
    <Banner urlName={"investor"} />
    </section>
   
      {/* banner */}

      {/* blog */}
      <section className="investore">
        <div className="container">
          <nav>
            <div
              className="nav nav-tabs gallery_tabs mb-3 justify-content-center"
              id="nav-tab"
              role="tablist">
              {data?.data?.attributes?.Tab.map((tabItem, index) => (
                  <li className="nav-item">
                                <a
                                  className={
                                    index === 0 ? "nav-link active" : "nav-link"
                                  }
                                  aria-current="page"
                                  data-bs-toggle="pill"
                                  href={
                                    "#" +
                                    tabItem.Title?.replace(
                                      /\s+/g,
                                      "-"
                                    ).toLowerCase()
                                  }
                                >
                                  {tabItem.Title}
                                </a>
                              </li>
               
              ))}
            </div>
          </nav>
          <div className="nuc_galiery investore_page">
            <div className="tab-content p-3" id="nav-tabContent">
              {data?.data?.attributes?.Tab.map((tabItem, index) =>  
              {
                return(
                  <DynamicContent htmlString={tabItem} index={index} />
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/* blog */}
    </>
  );
};
export default Investors;
