import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
  
export const careerDataApiCall = createApi({
  reducerPath: 'careerDataApiCall',
  tagTypes: ['careerData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchCareerData: build.query({
      query: () => {
        return ({
          url: `/api/career?populate=*`,
          
        })
      },
     
      providesTags: ['careerData']
    }),


 
  })
})
 
export const {
  useFetchCareerDataQuery,
 
} =careerDataApiCall;
 