// import React from 'react'
import React from 'react'
import Banner from './Banner'
import ContentDetail from './ContentDetails'

export default function MainComponent() {
    return (
        <div>
            <Banner urlName={"home"} hideUnderline={true}/>
            <ContentDetail/>
        </div>
    )
}
