import React, { useEffect, useState } from "react";
import { REACT_APP_API_URL, REACT_APP_BASE_BACKEND_URL } from "../config";
import { useFetchEventsDataQuery } from '../store/rtk/Events';
import Banner from "./Banner";
import { Loader } from "../shared/Loader";
export const Events = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const { isFetching, isLoading, data } = useFetchEventsDataQuery({ currentPage, pageSize });
  const [allEvent, setAllEvent] = useState([]);
  useEffect(() => {
    if (data) {
      setAllEvent(prevEvents => [...prevEvents, ...data.data]);
    }
  }, [data]);
  const handleShowMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };
  const hasMore = currentPage < Math.ceil(data?.meta?.pagination?.total / pageSize);
  return (
    <>
    {isFetching && <Loader/>}
      <section className="banner_image_common" >
        <Banner urlName={"events"} />
      </section>

      {/* Events */}
      <section className="news" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row gx-md-5">
            {allEvent?.map((item, i) => {

              return (
                <div className="col-lg-4 col-md-6 card-group">
                  <div className="card news-card events_card">
                    <img className="img-fluid" src={REACT_APP_BASE_BACKEND_URL + item?.attributes?.event_image?.data?.[0].attributes?.url} alt="Card imag cap" />
                    <div className="card-body content_events">
                      <div className="location d-flex align-items-center">
                        <img src={`${REACT_APP_API_URL}/assets/Svg/location-dot-light.svg`} alt="" />
                        <p className="card-text">
                          {item?.attributes?.location}
                        </p>
                      </div>
                      <div className="event_txt">
                        <p><b>{item?.attributes?.Title}</b></p>
                        {(item?.attributes?.short_description)? <p>{item?.attributes?.short_description} </p> :""}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}


            {!isLoading && !isFetching && hasMore && (
              <div className="show_more mt-0">
                <button className="btn btn-dark btn-title" onClick={handleShowMore}>SHOW MORE</button>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* Events */}
    </>
  );
};
