import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
  
export const oicplCommitteeDataApiCall = createApi({
  reducerPath: 'oicplCommitteeDataApiCall',
  tagTypes: ['oicplCommitteeData'],

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchoicplCommitteeData: build.query({
      query: () => {
        return ({
          url: `/api/oicpl-committee?populate=*`,
          
        })
      },
     
      providesTags: ['oicplCommitteeData']
    }),

    fetchNopplCommitteeData: build.query({
      query: () => {
        return ({
          url: `/api/noppl-committee?populate=*`,
          
        })
      },
     
      providesTags: ['oicplCommitteeData']
    }),

    fetchOicplGovernanceData: build.query({
      query: () => {
        return ({
          url: `/api/oicpl-governance?populate[Governance_file][populate]=File`,
          
        })
      },
     
      providesTags: ['oicplCommitteeData']
    }),

    fetchNopplGovernanceData: build.query({
      query: () => {
        return ({
          url: `/api/noppl-governance?populate[Noppl_governance_file][populate]=File`,
          
        })
      },
     
      providesTags: ['oicplCommitteeData']
    }),


    fetchOicplshareholdereData: build.query({
      query: () => {
        return ({
          url: `/api/oicpl-shareholder?populate[Shareholder_file][populate]=File`,
          
        })
      },
     
      providesTags: ['oicplCommitteeData']
    }),


    fetchNopplshareholdereData: build.query({
      query: () => {
        return ({
          url: `/api/noppl-shareholder?populate[Shareholder_file][populate]=File`,
          
        })
      },
     
      providesTags: ['oicplCommitteeData']
    }),

    fetchOicplContactData: build.query({
      query: () => {
        return ({
          url: `/api/oicpl-contact?populate=*`,
          
        })
      },
     
      providesTags: ['oicplCommitteeData']
    }),

    fetchNopplcontactData: build.query({
      query: () => {
        return ({
          url: `/api/noppl-contact?populate=*`,
          
        })
      },
     
      providesTags: ['oicplCommitteeData']
    }),

    fetchOicplCsrData: build.query({
      query: () => {
        return ({
          url: `/api/oicpl-csr?populate=*`,
          
        })
      },
     
      providesTags: ['oicplCommitteeData']
    }),

    fetchNopplCsrData: build.query({
      query: () => {
        return ({
          url: `/api/noppl-csr?populate=*`,
          
        })
      },
     
      providesTags: ['oicplCommitteeData']
    }),
 
  })
})
 
export const {
  useFetchoicplCommitteeDataQuery,
  useFetchNopplCommitteeDataQuery,
  useFetchOicplGovernanceDataQuery,
  useFetchNopplGovernanceDataQuery,
  useFetchOicplshareholdereDataQuery,
  useFetchNopplshareholdereDataQuery,
  useFetchOicplContactDataQuery,
  useFetchNopplcontactDataQuery,
  useFetchOicplCsrDataQuery,
  useFetchNopplCsrDataQuery,
 
} =oicplCommitteeDataApiCall;
 