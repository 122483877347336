import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
 
 
export const bannerApiCall = createApi({
  reducerPath: 'bannerApiCall',
  tagTypes: ['banner'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchBanner: build.query({
      query: (params = {}) => {
     
        return ({
          url: `/api/page-banners?filters[slug]=${params}&populate=*`,
         // params: params,
        })
      },
     
      providesTags: ['banner']
    }),
// for log
 
  })
})
 
export const {
  useFetchBannerQuery,
} = bannerApiCall;
 