import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
 
 
export const aboutUsApiCall = createApi({
  reducerPath: 'aboutUsApiCall',
  tagTypes: ['aboutusData'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_BACKEND_URL,
  }),
  endpoints: (build) => ({
    fetchAboutUsData: build.query({
      query: (params = {}) => {
        return ({
          url: `/api/aboutus?populate[aboutus_section][populate]=image&[populate]=portfolio_counts&populate[leader_ships][populate]=image&populate[Tab][populate]&populate[Transform][populate]=images&populate[Valuable_culture][populate]=image`,
        //  params: params,
        })
      },
     
      providesTags: ['aboutusData']
    }),
// for log
 
  })
})
 
export const {
  useFetchAboutUsDataQuery
} = aboutUsApiCall;
 