import React from 'react'
import { REACT_APP_BASE_BACKEND_URL } from "../config";
import PortfolioBanner from './PortfolioBanner';

import { useFetchPortfolioBengaluruDataQuery } from '../store/rtk/portfoiloPages';
import { Link } from 'react-router-dom';
import { Loader } from '../shared/Loader';

export const Bengaluru = () => {

  const {isFetching,data } = useFetchPortfolioBengaluruDataQuery();

  return (
    <>
     {isFetching && <Loader/>}
      <PortfolioBanner urlName={"bengaluru"} />
      <section className="mumbai_item" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row gy-5 gx-5">
            {data?.data?.map((item, index) => {

              return <>
                <div className="col-lg-4 col-md-6 card-group" key={index}>
                <Link to={item?.attributes?.Url}>
                  <div className="card border-0">
                    <img className="img-fluid" src={REACT_APP_BASE_BACKEND_URL + item?.attributes?.Image?.data?.attributes?.url} alt="Card imag cap" />

                    <div className="card-body">
                      <h5 className="card-title">{item?.attributes?.Title}</h5>
                      <p className="card-text">{item?.attributes?.Description} </p>
                      <div className="more_item">
                        <button className="read_more_btn">Read More</button>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </>
            })}
          </div>
        </div>
      </section>
    </>
  )
}
