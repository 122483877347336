import React from "react";

const Center = ({
  children,
  backgroundColor = "rgb(255,255,255)",
  id,
  height = "100vh",
}) => {


  return (
    <div id={id}>
      {children}
    </div>
  );
};

export default Center;
