import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

const TeamMember = ({
  name,
  title,
  description,
  detaiDescription,
  id,
  imgSrc,
  imgAlt,
  readMoreBtn,
  onShowDetails,
  isActive,
  activeMember,
  setIsVisible,
}) => {
  const handleClick = () => {
    if (isActive) {
      onShowDetails(null);
    } else {
      onShowDetails({ name, title, description, detaiDescription, id });
    }
    setIsVisible(false); // Hide content first
    setTimeout(() => setIsVisible(true), 10); // Re-trigger animation after a short delay
  };

  return (
    <div className="col-md col-12 text-center mb-4" onClick={handleClick}>
      <div className={` ${isActive ? "" : "hover_image"}`}>
        <div className="led_imag">
          <img
            src={imgSrc}
            alt={imgAlt}
            className={`img-fluid border-image member-photo card-img-top ${
              isActive ? "border-primary" : ""
            }`}
          />
        </div>
        <div className="leader_details">
          <div className="leader_content d-flex justify-content-between">
            <div className="leader_name">
              <h5 className="card-title">{name}</h5>
              <p className="">{title}</p>
            </div>
            <div className="toggle_icon">
              <button className="accordion-button" >
                {/* <i className={`right ${!isActive?"bi bi-arrow-right":"fa-solid fa-xmark"}`}></i> */}
                {!isActive ? (
                  <i className="bi bi-arrow-right right"></i>
                ) : (
                  <i class="fa-solid fa-xmark right"></i>
                )}
              </button>
            </div>
          </div>

          <CSSTransition
            in={!!isActive}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div className="hide_one_lg_device">
              <p
                className="card-text text-start fs-17"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              {activeMember?.detaiDescription && (
                <div className="read_more">
                  <Link to={`/leader-ship-details-page/${activeMember?.id}`}>
                    <button className="btn btn-dark">Read More</button>
                  </Link>
                </div>
              )}
            </div>
          </CSSTransition>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
